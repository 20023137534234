import { Anchor, Button, Text } from 'grommet'
import getConfig from 'next/config'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { assembleWebAppAddress, routes } from 'lib/routes'

import { colors } from 'layout/themeColors/colors'

export interface SignUpButtonProps {
  label?: string
}

const {
  publicRuntimeConfig: { MARKETPLACE_SIGN_UP_BUTTON_LINK },
} = getConfig()

export function SignUpButton({ label }: SignUpButtonProps) {
  const { t } = useTranslation()

  return (
    <Anchor
      href={MARKETPLACE_SIGN_UP_BUTTON_LINK || assembleWebAppAddress(routes.signup.index)}
      target="_blank">
      <Button
        style={{ background: colors.primaryBrand, width: 'fit-content', alignSelf: 'center' }}
        label={
          <Text style={{ fontWeight: 500 }} size="14px" color={colors.white}>
            {label ?? t('marketplace.sign-up')}
          </Text>
        }
      />
    </Anchor>
  )
}
