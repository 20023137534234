import React from 'react'

import { colors } from 'layout/themeColors/colors'

interface SearchIconProps {
  width?: number
  height?: number
  color?: string
}

export function SearchIcon({
  height = 13,
  width = 13,
  color = colors.statusDisabled,
}: SearchIconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5625 14.25C11.1869 14.25 14.125 11.3119 14.125 7.6875C14.125 4.06313 11.1869 1.125 7.5625 1.125C3.93813 1.125 1 4.06313 1 7.6875C1 11.3119 3.93813 14.25 7.5625 14.25Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7499 16.875L12.2 12.325"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
