import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { SearchIcon } from 'components/icons/common/SearchIcon'
import { WhiteTextInput } from 'components/WhiteTextInput'
import { colors } from 'layout/themeColors/colors'

interface ProvidersSearchProps {
  onChange: Dispatch<SetStateAction<string | undefined>>
}

export function ProvidersSearch({ onChange }: ProvidersSearchProps) {
  const { t } = useTranslation()

  return (
    <WhiteTextInput
      type="search"
      icon={<SearchIcon width={20} height={20} color={colors.accent5} />}
      onChange={({ target }) => onChange(target.value.toLowerCase())}
      style={{
        width: '100%',
        padding: '0 5px 0px 50px',
        height: '60px',
        borderRadius: '16px',
        background: colors.light2,
      }}
      placeholder={t('marketplace.search-placeholder')}
    />
  )
}
