import { Box, ResponsiveContext, Text } from 'grommet'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { assembleWebAppAddress, routes } from 'lib/routes'

import { BaseBanner } from 'components/BaseBanner'
import { ButtonLikeLink } from 'components/ButtonLikeLink'
import { LanguageChanger } from 'components/LanguageChanger'
import { colors } from 'layout/themeColors/colors'

import { SignUpButton } from './components/SignUpButton'

const {
  publicRuntimeConfig: { shouldShowMarketplaceLanguageChanger },
} = getConfig()

export interface MarketplaceBannerProps {
  signUpButtonLabel?: string
}

export function MarketplaceBanner({ signUpButtonLabel }: MarketplaceBannerProps) {
  const { t } = useTranslation()
  const size = React.useContext(ResponsiveContext)
  const isMobile = size === 'small'
  const router = useRouter()

  return (
    <>
      <BaseBanner bannerColor={colors.white}>
        <Box align="end">
          <Box
            justify="start"
            direction={isMobile ? 'column' : 'row-reverse'}
            align={isMobile ? 'end' : 'center'}
            gap={isMobile ? '24px' : '48px'}>
            <Box direction="row" gap="medium">
              {isMobile && shouldShowMarketplaceLanguageChanger && (
                <LanguageChanger background={colors.white} />
              )}
              <ButtonLikeLink
                onClick={() => router.push(assembleWebAppAddress(routes.login.index))}
                plain
                label={
                  <Text
                    style={{
                      fontSize: '14px',
                      lineHeight: '20px',
                      fontWeight: 500,
                    }}
                    color={colors.primaryBrand}>
                    {t('login-form.title')}
                  </Text>
                }
              />
            </Box>

            {!isMobile && <SignUpButton label={signUpButtonLabel} />}
          </Box>
          {!isMobile && shouldShowMarketplaceLanguageChanger && (
            <Box style={{ position: 'relative', top: '12px' }}>
              <LanguageChanger background={colors.white} />
            </Box>
          )}
        </Box>
      </BaseBanner>
      {isMobile && (
        <Box style={{ alignSelf: 'center' }}>
          <SignUpButton label={signUpButtonLabel} />
        </Box>
      )}
    </>
  )
}
