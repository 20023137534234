import i18next from 'i18next'
import Head from 'next/head'
import { useTranslation } from 'react-i18next'

import { FALLBACK_LANG, MarketplaceCategory } from 'lib/constants'

import { StyledUnauthorizedLayout } from 'layout/StyledUnauthorizedLayout'
import { colors } from 'layout/themeColors/colors'
import { ProviderUser } from 'services/api/Users'

import { MarketplaceBanner } from './components/MarketplaceBanner/MarketplaceBanner'
import { MarketplacePage } from './MarketplacePage'

type MarketplacePageWithLayoutProps = {
  currentUrl: string
  marketplaceCategories?: MarketplaceCategory[]
  providers?: ProviderUser[]
  translations?: {
    [key: string]: {
      [key: string]: string
    }
  }
}

export default function MarketplacePageWithLayout({
  currentUrl,
  marketplaceCategories,
  providers,
  translations,
}: MarketplacePageWithLayoutProps) {
  const { t } = useTranslation()
  const lang = i18next.language
  const marketplaceTranslations = translations?.[lang] ?? translations?.[FALLBACK_LANG]

  const whiteLabelMetaTitle =
    translations && Object.keys(translations).length > 0
      ? (marketplaceTranslations?.['title-part-one'] ?? '') +
        ' ' +
        (marketplaceTranslations?.['title-part-two'] ?? '') +
        ' ' +
        (marketplaceTranslations?.['title-part-three'] ?? '')
      : undefined
  const metaTitle = whiteLabelMetaTitle || t('marketplace.title')
  const description = marketplaceTranslations?.['slogan'] || metaTitle

  return (
    <>
      <Head>
        <title>{metaTitle}</title>
        <meta key="og:title" property="og:title" content={metaTitle} />
        <meta key="description" name="description" content={description} />
        <meta key="og:description" property="og:description" content={description} />
        <link rel="canonical" href={currentUrl} />
      </Head>

      <StyledUnauthorizedLayout
        banner={
          <MarketplaceBanner
            signUpButtonLabel={marketplaceTranslations?.['sign-up-button-label']}
          />
        }
        background={colors.white}>
        <MarketplacePage
          translations={marketplaceTranslations}
          marketplaceCategories={marketplaceCategories}
          initialProviders={providers}
        />
      </StyledUnauthorizedLayout>
    </>
  )
}
