import React from 'react'

import { colors } from 'layout/themeColors/colors'

interface ChangeLangIconProps {
  width?: number
  height?: number
}

function ChangeLangIcon({ width, height }: ChangeLangIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || 11}
      height={height || 10}
      fill="none"
      viewBox="0 0 11 10">
      <path
        fill={colors.primaryBrand}
        fillRule="evenodd"
        d="M5.5 0a5 5 0 110 10 5 5 0 010-10zm3.765 3.077a4.252 4.252 0 00-2.349-2.059c.342.647.589 1.339.733 2.059h1.616zm.447 2.304a4.203 4.203 0 00-.145-1.535H7.765c.025.255.043.51.043.77a7.78 7.78 0 01-.159 1.538h1.918c.073-.253.121-.512.146-.773zm-2.719 0l.005-.04c.026-.24.04-.483.04-.726a6.917 6.917 0 00-.058-.769H4.02a6.784 6.784 0 00-.058.77c.001.517.062 1.033.179 1.538h2.718a6.94 6.94 0 00.134-.773zm-.148-2.304A6.903 6.903 0 005.88.792C5.754.782 5.628.77 5.5.77c-.08 0-.16.004-.24.012l-.14.011L5.115.8a6.9 6.9 0 00-.96 2.277h2.69zm-3.494 0a7.66 7.66 0 01.743-2.062 4.252 4.252 0 00-2.359 2.062h1.616zm-1.918.77A4.215 4.215 0 001.27 5c.001.39.056.779.164 1.154h1.918a7.704 7.704 0 01-.159-1.539c0-.258.018-.514.043-.769H1.433zm2.119 3.076H1.736a4.242 4.242 0 002.938 2.225 7.71 7.71 0 01-1.122-2.225zM5.5 8.957a6.878 6.878 0 001.137-2.034H4.363A6.88 6.88 0 005.5 8.957zm1.948-2.034a7.71 7.71 0 01-1.122 2.225 4.244 4.244 0 002.938-2.225H7.448z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default ChangeLangIcon
