import { Box, Select, Text, ThemeContext } from 'grommet'
import i18next from 'i18next'
import React, { useMemo } from 'react'

import { useApi } from '../config/api'
import useGTM from '../config/useGTM'
import { colors } from '../layout/themeColors/colors'
import { LANGUAGES } from '../lib/constants'
import UserEndpoints from '../services/api/Users'

import { useUserContext } from './contexts/UserProvider'
import ChangeLangIcon from './icons/common/ChangeLangIcon'

interface LanguageChangerProps {
  fontSize?: number
  iconWidth?: number
  iconHeight?: number
  background?: string
}

export const LanguageChanger = ({
  fontSize,
  iconWidth,
  iconHeight,
  background = colors.light1,
}: LanguageChangerProps) => {
  const { user } = useUserContext()

  const [, edit] = useApi(UserEndpoints.edit(user?.id ?? ''), { manual: true })

  const { trackEvent } = useGTM()

  const currLang = useMemo(
    () => LANGUAGES.find((lang) => lang.value === i18next.language) || LANGUAGES[0],
    [i18next.language],
  )

  return (
    <ThemeContext.Extend value={{ select: { background } }}>
      <Box direction="row" gap="small" align="center">
        <Box>
          <Select
            plain
            options={LANGUAGES}
            value={currLang}
            valueKey="value"
            labelKey="label"
            valueLabel={
              <Box direction="row" gap="xsmall" align="center">
                <ChangeLangIcon width={iconWidth} height={iconHeight} />
                <Text size={`${fontSize}px` || '12px'} weight={600}>
                  {currLang.label}
                </Text>
              </Box>
            }
            onChange={async ({ option }) => {
              trackEvent({
                event: 'change_language',
                prev_lang: i18next.language,
                user_lang: option.value,
              })
              await i18next.changeLanguage(option.value)
              if (user) {
                await edit({
                  data: {
                    iso_language_code: option.value,
                  },
                })
              }
            }}>
            {(option, state) => (
              <Box pad="small" background={state.active ? 'active' : undefined}>
                <Text size={`${fontSize}px` || '12px'}>{option.label}</Text>
              </Box>
            )}
          </Select>
        </Box>
      </Box>
    </ThemeContext.Extend>
  )
}
