import { Box, ResponsiveContext, Text } from 'grommet'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { MarketplaceCategory } from 'lib/constants'

import Spinner from 'components/Spinner'
import { ProviderUser } from 'services/api/Users'

import { MarketplaceProviderCard } from './MarketplaceProviderCard'

interface ProvidersGridProps {
  providers: ProviderUser[] | undefined
  providersLoading: boolean
  sortedMarketplaceCategories: MarketplaceCategory[]
  translations:
    | {
        [key: string]: string
      }
    | undefined
}

export function ProvidersGrid({
  providers,
  providersLoading,
  sortedMarketplaceCategories,
  translations,
}: ProvidersGridProps) {
  const deviceSize = React.useContext(ResponsiveContext)
  const isMobile = deviceSize === 'small'
  const isMediumSize = deviceSize === 'mediumS'

  const { t } = useTranslation()

  if (!providers?.length && !providersLoading) return <Text>{t('marketplace.no-providers')}</Text>

  return (
    <Box gap="large" width="100%">
      {providersLoading && <Spinner />}
      <Box
        justify={isMobile || isMediumSize ? 'center' : 'between'}
        style={{
          display: 'grid',
          gap: '30px',
          width: '100%',
          gridTemplateColumns: 'repeat(auto-fit, min(100%, 360px)',
        }}>
        {providers?.map((provider) => (
          <MarketplaceProviderCard
            sortedMarketplaceCategories={sortedMarketplaceCategories}
            key={provider.id}
            selectedCategoryOptions={provider.marketplace_category_options}
            provider={provider}
            translations={translations}
          />
        ))}
      </Box>
    </Box>
  )
}
