import { Box, Image, ResponsiveContext, Text, ThemeContext } from 'grommet'
import getConfig from 'next/config'
import React, { useEffect, useMemo, useState } from 'react'

import { useApi } from 'config/api'
import { MarketplaceCategory, UserMarketplaceCategoryOptions } from 'lib/constants'
import { sortMarketplaceCategoriesByListOrder } from 'lib/helpers'

import { useForcedLogo } from 'components/contexts/ForcedLogoProvider'
import { colors } from 'layout/themeColors/colors'
import UserEndpoints, { ProviderUser } from 'services/api/Users'

import { MarketplacePlaceFilters } from './components/MarketplacePlaceFilters'
import { MarketplaceSelectedFilterTags } from './components/MarketplaceSelectedFilterTags'
import { ProvidersGrid } from './components/ProvidersGrid'
import { ProvidersSearch } from './components/ProvidersSearch'

const {
  publicRuntimeConfig: {
    CDN_URL,
    featureFlags: { isMarketplaceEnabled },
  },
} = getConfig()

interface MarketplacePageProps {
  marketplaceCategories: MarketplaceCategory[] | undefined
  initialProviders: ProviderUser[] | undefined
  translations:
    | {
        [key: string]: string
      }
    | undefined
}

export function MarketplacePage({
  marketplaceCategories,
  initialProviders,
  translations,
}: MarketplacePageProps) {
  if (!isMarketplaceEnabled || !marketplaceCategories) return null
  const deviceSize = React.useContext(ResponsiveContext)
  const isMobile = deviceSize === 'small'
  const isMediumSize = deviceSize === 'mediumS'

  const [search, setSearch] = useState<string>()
  const [selectedCategoryOptions, setSelectedCategoryOptions] =
    useState<UserMarketplaceCategoryOptions>()
  const [providers, setProviders] = useState<ProviderUser[] | undefined>(initialProviders)
  const { forcedMarketplaceBanner } = useForcedLogo()

  const sortedMarketplaceCategories = useMemo(() => {
    return sortMarketplaceCategoriesByListOrder(marketplaceCategories)
  }, [marketplaceCategories])

  const filterUrlQuery = useMemo<string | undefined>(() => {
    if (!selectedCategoryOptions) return ''
    let query = ''

    for (const key in selectedCategoryOptions) {
      const categoryOptions = selectedCategoryOptions[key]
      query = query
        .concat(key)
        .concat(`[${categoryOptions.map((categoryOption) => categoryOption.id).toString()}],`)
    }
    return query ? query.slice(0, -1) : undefined
  }, [selectedCategoryOptions])

  const [{ data, loading }, listProviders] = useApi(
    UserEndpoints.listMarketplaceProviders(undefined, search, filterUrlQuery),
    {
      useCache: false,
      manual: true,
    },
  )

  useEffect(() => {
    if (data) {
      setProviders(data?.users)
    }
  }, [data])

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (typeof search !== 'undefined') {
        await listProviders()
      }
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [search])

  useEffect(() => {
    const helper = async () => {
      if (typeof selectedCategoryOptions !== 'undefined') {
        await listProviders()
      }
    }
    helper()
  }, [selectedCategoryOptions])

  return (
    <ThemeContext.Extend
      value={{
        text: {
          extend: {},
        },
      }}>
      <Box
        width="100%"
        align="center"
        gap="medium"
        pad={{
          left: isMobile ? '40px' : isMediumSize ? '100px' : '150px',
          right: isMobile ? '40px' : isMediumSize ? '100px' : '150px',
          bottom: '40px',
        }}>
        <Text size="40px" weight={700} textAlign="center">
          {translations?.['title-part-one'] ? translations?.['title-part-one'] + ' ' : ''}
          <Text size="40px" weight={700} color={colors.primaryBrand}>
            {translations?.['title-part-two'] ? translations?.['title-part-two'] + ' ' : ''}
          </Text>
          <Text size="40px" weight={700}>
            {translations?.['title-part-three'] ?? ''}
          </Text>
        </Text>
        <Text size="24px" textAlign="center">
          {translations?.['slogan']}
        </Text>
        <Box
          pad={{ top: isMobile ? '12px' : '36px' }}
          gap={isMobile ? '24px' : '60px'}
          width="100%">
          {!isMobile && (
            <Image src={forcedMarketplaceBanner || `${CDN_URL}static/marketplace-banner.png`} />
          )}
          <Box gap={isMobile ? '20px' : '16px'} align="center">
            <ProvidersSearch onChange={setSearch} />
            <MarketplacePlaceFilters
              selectedCategoryOptions={selectedCategoryOptions}
              setSelectedCategoryOptions={setSelectedCategoryOptions}
              sortedMarketplaceCategories={sortedMarketplaceCategories}
              loading={loading}
              translations={translations}
            />
          </Box>
        </Box>
        <MarketplaceSelectedFilterTags
          selectedCategoryOptions={selectedCategoryOptions}
          setSelectedCategoryOptions={setSelectedCategoryOptions}
          sortedMarketplaceCategories={sortedMarketplaceCategories}
          loading={loading}
          translations={translations}
        />
        <ProvidersGrid
          providers={providers}
          providersLoading={loading}
          sortedMarketplaceCategories={sortedMarketplaceCategories}
          translations={translations}
        />
      </Box>
    </ThemeContext.Extend>
  )
}
