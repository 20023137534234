import { Box, Heading, ResponsiveContext } from 'grommet'
import React, { useContext } from 'react'

interface StyledUnauthorizedLayoutProps {
  banner?: React.ReactElement
  children: React.ReactElement
  title?: string
  background?: string
}

export const StyledUnauthorizedLayout = ({
  banner,
  children,
  title,
  background = 'white',
}: StyledUnauthorizedLayoutProps) => {
  const deviceSize = useContext(ResponsiveContext)
  const isMobile = deviceSize === 'small'
  return (
    <Box background={background} style={{ minHeight: '100vh' }} gap="medium">
      {banner}
      {title && (
        <Heading
          margin={{ top: 'large', bottom: 'small' }}
          style={{ maxWidth: '100%', fontSize: isMobile ? '36px' : '42px' }}
          textAlign="center">
          {title}
        </Heading>
      )}
      {children}
    </Box>
  )
}
