import { Box, ResponsiveContext } from 'grommet'
import React, { useContext } from 'react'

import { MarketplaceCategory, UserMarketplaceCategoryOptions } from 'lib/constants'
import { handleTagSelectionChange } from 'lib/helpers'

import { MarketplaceCategoryOptionTag } from './MarketplaceCategoryOptionTag'

interface MarketplaceCategoryOptionTagsProps {
  sortedMarketplaceCategories: MarketplaceCategory[]
  selectedCategoryOptions: UserMarketplaceCategoryOptions | undefined
  setSelectedCategoryOptions: React.Dispatch<
    React.SetStateAction<UserMarketplaceCategoryOptions | undefined>
  >
  loading?: boolean
  translations:
    | {
        [key: string]: string
      }
    | undefined
}

export function MarketplaceSelectedFilterTags({
  selectedCategoryOptions,
  setSelectedCategoryOptions,
  sortedMarketplaceCategories,
  loading,
  translations,
}: MarketplaceCategoryOptionTagsProps) {
  const deviceSize = useContext(ResponsiveContext)
  const isMobile = deviceSize === 'small'

  if (!selectedCategoryOptions) return null

  return (
    <Box
      wrap
      direction="row"
      style={{ gap: '16px', pointerEvents: loading ? 'none' : undefined }}
      width="100%"
      margin={{ top: isMobile ? undefined : '36px', bottom: '16px' }}>
      {sortedMarketplaceCategories.map((marketplaceCategory) =>
        selectedCategoryOptions?.[marketplaceCategory.id]?.map((option) => (
          <MarketplaceCategoryOptionTag
            key={option.id}
            label={translations?.[option.id] || option.display_name}
            onClear={() => {
              handleTagSelectionChange(
                marketplaceCategory,
                option,
                selectedCategoryOptions,
                setSelectedCategoryOptions,
              )
            }}
          />
        )),
      )}
    </Box>
  )
}
